import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useClientSideCartAndUser, SiteWideSSRProps, getSiteWideInitialProps } from '@/services/getSiteWideInitialProps'

import type { NextPage } from 'next'
import type { IMeganav } from '@/types/Meganav'
import type { SitewideBanner } from '@/types/Contentful/SitewideBanner'
import { error } from '@/services/Log'

const Heading1 = dynamic(import('@csc/dls/Heading1'))
const Row = dynamic(import('@csc/dls/Row'))
const PrimaryButton = dynamic(import('@csc/dls/PrimaryButton'))

const Default = dynamic(import('@/layouts/default'))
const DLSLink = dynamic(import('@csc/dls/Link'))
const Link = dynamic(import('next/link'))

type NotFoundPageProps = SiteWideSSRProps
const NotFoundPage: NextPage<NotFoundPageProps> = () => {
  const {
    cart, setCart, user, reloadCustomer,
  } = useClientSideCartAndUser()
  const [megaNav, setMegaNav] = useState<IMeganav | null>(null)
  const [sitewideBanner, setSitewideBanner] = useState<SitewideBanner | null>(null)
  useEffect(() => {
    if (!window.location.href.includes('PageNotFound')) {
      const urlParams = new URLSearchParams(window.location.search)
      const path = window.location.pathname
      urlParams.set('pageSlug', encodeURIComponent(path))
      window.location.href = `/PageNotFound?${urlParams.toString()}`
    }
  }, [])
  useEffect(() => {
    getSiteWideInitialProps({
      res: { setHeader: () => { } },
      asPath: '',
      req: {
        headers: {},
      },
      query: {},
    })
      .then(({
        megaNav: newMegaNav,
        sitewideBanner: newSitewideBanner,
      }) => {
        setMegaNav(newMegaNav)
        setSitewideBanner(newSitewideBanner)
      })
      .catch((e) => {
        error('Error fetching sitewide props', e)
      })
  }, [])
  return (
    <Default
      reloadCustomer={reloadCustomer}
      user={user}
      sitewideBanner={sitewideBanner}
      megaNav={megaNav}
      meta={{
        title: 'Page Not Found',
        description: 'Page Not Found',
      }}
      cart={cart}
      onUpdateCart={setCart}
      eventBanner={null}
    >
      <div className="w-full text-center h-60 flex items-center justify-center">
        <Row
          gapY="3xl"
          cols={1}
        >
          <Heading1
            className="w-full text-center flex items-center justify-center"
            textTag="h2"
          >
            404 | This page could not be found
          </Heading1>
          <Link href="/">
            <DLSLink className="no-underline">
              <PrimaryButton
                htmlType="button"
                type="primary"
              >
                Continue to Home Page
              </PrimaryButton>
            </DLSLink>
          </Link>
        </Row>
      </div>
    </Default>
  )
}

export default NotFoundPage
